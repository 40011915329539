import React, { useState, useEffect } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from "axios";
import Swal from "sweetalert2";

import * as XLSX from "xlsx";

import "../Carousel/Carousel_3_finalizado.sass"

const Carousel_3 = ( {data} ) => {
    //const baseURL = "https://api.torneosbetplay.com.co/";
    const baseURL = "https://torneoastrobet.bet/";

    let navigate = useNavigate();

    const [indexCarousel, setIndexCarousel] = useState(0)
    const [dataSelect, setDataSelect] = useState([])
    const [dataImg, setDataImg] = useState([])

    const nextSlide = () => {
        if(data.length > 3){
            setIndexCarousel(indexCarousel === data.length - 1 ? 0 : indexCarousel + 1);
        }
    }

    const previousSlide = () => {
        if(data.length > 3){
            setIndexCarousel(indexCarousel === 0 ? data.length - 1 : indexCarousel - 1);
        }
    }

    const cargarDatosSelect = async () =>{
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        let data = await axios.get(baseURL + "api/get/torneo/all").then(e =>{
    
          if(e.data.length == 0){
            Swal.close();
          }else{
            setDataSelect(e.data.torneos)
            Swal.close();
          }
        });
      }

    const handleDonwloadExcel = (index) => {
        //e.preventDefault()
        const decodedData = JSON.parse(dataSelect[index].excelData)
        //setBase64STR(decodedData);
        const downloadLink = document.createElement("a");

        //nuevo metodo

        const worksheet = XLSX.utils.json_to_sheet(decodedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "ranking-torneo.xlsx");
    
        //downloadLink.href = decodedData;
        
        //downloadLink.download = "ranking-torneo.xlsx";
        
        //downloadLink.click();
        /*fs.writeFile(outputPath, decodedData, (error) => {
        if (error) {
            console.error('Error writing the PDF file:', error);
        } else {
            console.log('PDF file saved successfully at', outputPath);
        }
        });*/
    }

    useEffect(() => {
        cargarDatosSelect()
    }, []);

    return (
        <>
            <body className='body_carousel_3_sss'>
                <div className='carousel_grid'>
                    <div className='carousel_div'>

                        
                        {
                            
                            data[indexCarousel]?
                            <div className='div_image'>
                            
                                <a onClick={() => {
                                    handleDonwloadExcel(indexCarousel % data.length)
                                }}>    
                                    <img src={data[indexCarousel % data.length].src[0]} key={0} className='carousel_img' />
                                </a>

                                <div className='card_title'>
                                    <h3>{data[indexCarousel % data.length].titulo[0]}</h3>
                                    <p>{data[indexCarousel % data.length].decripcion[0]}</p>
                                    <Button variant='contained' 
                                    onClick={() => {
                                        //e.preventDefault();
                                        navigate(data[indexCarousel % data.length].link[0]);
                                        
                                    }}
                                    >Ingresa Ahora</Button>
                                </div>

                            </div>
                            :
                            <></>
                        }
                            
                        {
                            
                            data[(indexCarousel + 1) % data.length]?
                                data.length > 1?
                                <div className='div_image_plus2'>
                                
                                    <a onClick={() => {
                                        handleDonwloadExcel(indexCarousel)
                                    }}>    
                                        <img src={data[(indexCarousel + 1) % data.length].src[0]} key={0} className='carousel_img' />
                                    </a>

                                    <div className='card_title'>
                                        <h3>{data[(indexCarousel + 1) % data.length].titulo[0]}</h3>
                                        <p>{data[(indexCarousel + 1) % data.length].decripcion[0]}</p>
                                        
                                        <Button variant='contained' 
                                        onClick={() => {
                                            //e.preventDefault();
                                            navigate(data[(indexCarousel + 1) % data.length].link[0]);
                                            
                                        }}
                                        >Ingresa Ahora</Button>
                                    </div>

                                </div>
                                :
                                <></>
                            :
                            <></>
                        }
                            
                        {
                            
                            data[(indexCarousel + 2) % data.length]?
                                data.length > 2?
                                <div className='div_image_plus2'>
                                
                                    <a onClick={() => {
                                        handleDonwloadExcel(indexCarousel)
                                    }}>    
                                        <img src={data[(indexCarousel + 2) % data.length].src[0]} key={0} className='carousel_img' />
                                    </a>

                                    <div className='card_title'>
                                        <h3>{data[(indexCarousel + 2) % data.length].titulo[0]}</h3>
                                        <p>{data[(indexCarousel + 2) % data.length].decripcion[0]}</p>
                                        
                                        <Button variant='contained' 
                                        onClick={() => {
                                            //e.preventDefault();
                                            navigate(data[(indexCarousel + 2) % data.length].link[0]);
                                            
                                        }}
                                        >Ingresa Ahora</Button>
                                    </div>

                                </div>
                                :
                                <></>
                            :
                            <></>
                        }
                        
                    </div>
                    <div className='arrow_div_left'>
                        <IoIosArrowBack className={(dataSelect.length == 0)?'arrow arrow_none':'arrow arrow_left'} onClick={previousSlide}/>
                    </div>
                    <div className='arrow_div_right'>
                        <IoIosArrowForward className={(dataSelect.length == 0)?'arrow arrow_none':'arrow arrow_right'} onClick={nextSlide}/>
                    </div>
                    
                    
                </div>
            </body>
            
        </>
    )
}

export default Carousel_3;